<template>
    <div class="auth_wrapper flex justify-center items-center relative h-full overflow-y-auto">

        <div class="relative left px-24 h-full" :style="{'--bg':backgroundImage}">
            <router-link to="/" class="absolute top-12 left-12 cursor-pointer flex items-center gap-2 fs-18 fw-600 text-white">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.25 12H3.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 5.25L3.75 12L10.5 18.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Back to Website
            </router-link>

            <div class="left-info-box">
                <h1 class="title fs-46px fw-800 text-white mb-4">Imagine if Organizations</h1>
                <p class="description fs-32 fw-300 text-white">
                    always had the
                    <strong class="fw-600">Right Data</strong> and
                    <strong class="fw-600">Synchronized  Tools</strong> available to make
                    <strong class="fw-600">Smarter</strong> and more
                    <strong class="fw-600">Informed</strong> decisions and
                    <strong class="fw-600">Repeatable</strong> plays.
                </p>
            </div>
        </div>

        <div class="right relative px-52 justify-center bg-primary-three w-full h-full overflow-y-auto">
            <div class="logo absolute top-12 right-10">
                <svg width="253" height="32" viewBox="0 0 253 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 31.1097V0.521625H6.15306V31.1097H0Z" fill="#1C1C1D"/>
                    <path d="M14.2269 31.1097V0.521625H20.2136L32.686 20.9485V0.521625H38.4026V31.1097H32.2287L19.9434 11.1628V31.1097H14.2269Z" fill="#1C1C1D"/>
                    <path d="M45.4994 21.1571L51.4861 20.5729C51.8464 22.5898 52.574 24.0712 53.6688 25.0171C54.7775 25.963 56.2672 26.4359 58.1381 26.4359C60.1198 26.4359 61.6096 26.0186 62.6074 25.184C63.619 24.3355 64.1248 23.3479 64.1248 22.2212C64.1248 21.4979 63.91 20.8859 63.4804 20.3851C63.0647 19.8704 62.3302 19.4253 61.277 19.0497C60.5563 18.7994 58.9141 18.3542 56.3504 17.7144C53.0521 16.8937 50.7378 15.8852 49.4074 14.689C47.5365 13.0058 46.6011 10.9541 46.6011 8.53378C46.6011 6.97586 47.0376 5.52227 47.9107 4.173C48.7976 2.80982 50.0657 1.77352 51.7148 1.06411C53.3778 0.354705 55.3803 0 57.7223 0C61.5472 0 64.4228 0.841555 66.3491 2.52466C68.2892 4.20777 69.3078 6.45424 69.4048 9.26406L63.2518 9.5353C62.9885 7.96347 62.4203 6.83676 61.5472 6.15517C60.688 5.45967 59.3923 5.11192 57.66 5.11192C55.8723 5.11192 54.4726 5.48054 53.4609 6.21777C52.8096 6.69071 52.4839 7.32361 52.4839 8.11648C52.4839 8.8398 52.7888 9.4588 53.3986 9.97347C54.1746 10.6272 56.0593 11.3088 59.0527 12.0182C62.0461 12.7276 64.2565 13.4649 65.6839 14.2299C67.1251 14.9811 68.2477 16.0174 69.0514 17.3388C69.8691 18.6463 70.2779 20.2669 70.2779 22.2004C70.2779 23.953 69.7929 25.5944 68.8228 27.1245C67.8527 28.6546 66.4807 29.7952 64.7069 30.5463C62.933 31.2836 60.7226 31.6522 58.0757 31.6522C54.2231 31.6522 51.2644 30.762 49.1995 28.9815C47.1346 27.1871 45.9013 24.579 45.4994 21.1571Z" fill="#1C1C1D"/>
                    <path d="M84.4424 31.1097V5.69614H75.3999V0.521625H99.6172V5.69614H90.5955V31.1097H84.4424Z" fill="#1C1C1D"/>
                    <path d="M105.758 31.1097V0.521625H118.708C121.965 0.521625 124.328 0.799825 125.797 1.35622C127.28 1.89871 128.464 2.87241 129.351 4.27732C130.238 5.68223 130.682 7.28884 130.682 9.09714C130.682 11.3923 130.01 13.291 128.665 14.7933C127.321 16.2816 125.312 17.2206 122.637 17.6101C123.967 18.389 125.062 19.2445 125.921 20.1764C126.795 21.1084 127.966 22.7637 129.435 25.1423L133.155 31.1097H125.797L121.348 24.4538C119.768 22.0752 118.687 20.5798 118.105 19.9678C117.523 19.3418 116.907 18.9176 116.255 18.695C115.604 18.4586 114.572 18.3403 113.158 18.3403H111.911V31.1097H105.758ZM111.911 13.4579H116.463C119.415 13.4579 121.258 13.3327 121.993 13.0823C122.727 12.832 123.302 12.4008 123.718 11.7887C124.134 11.1767 124.342 10.4116 124.342 9.49357C124.342 8.46423 124.064 7.63659 123.51 7.01064C122.97 6.37078 122.201 5.96739 121.203 5.80047C120.704 5.73092 119.207 5.69614 116.713 5.69614H111.911V13.4579Z" fill="#1C1C1D"/>
                    <path d="M166.112 31.1097H159.418L156.757 24.1617H144.576L142.061 31.1097H135.534L147.403 0.521625H153.91L166.112 31.1097ZM154.783 19.008L150.584 7.65745L146.468 19.008H154.783Z" fill="#1C1C1D"/>
                    <path d="M175.225 31.1097V5.69614H166.182V0.521625H190.4V5.69614H181.378V31.1097H175.225Z" fill="#1C1C1D"/>
                    <rect x="208.605" y="13.2979" width="6.2085" height="17.7608" fill="#1C1C1D"/>
                    <rect x="221.023" y="3.5293" width="6.2085" height="27.5293" fill="#1C1C1D"/>
                    <rect x="245.855" y="13.2979" width="6.2085" height="17.7608" fill="#1C1C1D"/>
                    <rect x="233.441" y="19.5142" width="6.20848" height="11.5445" fill="#1C1C1D"/>
                </svg>
            </div>
            <div class="flex flex-col w-full">

                <form class="flex-row w-full m-0">
                    <div class="mb-10 w-full">
                        <h3 class="fs-28 fw-700 mb-1 welcome-text">Forgot password?</h3>
                        <p class="fw-300 fs-20 subtitle">Generate a link to reset password</p>
                    </div>


                    <div class="form_group relative">
                      <input type="email" autocomplete="on" v-model="cred.email" placeholder="Email" >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM5.51859 6L12 11.6712L18.4814 6H5.51859ZM20 7.32877L12.6585 13.7526C12.2815 14.0825 11.7185 14.0825 11.3415 13.7526L4 7.32877V18H20V7.32877Z" fill="#3D3D3D"/>
                      </svg>
                    </div>

                    <div class="flex-row">
                      <div class="max-w-full mb-8">
                        <button class="w-full block uppercase rounded text-center fw-600 focus:outline-none fs-18 bg-primary-one text-primary-two py-2" @click="generateResetLink($event)">Generate Reset Link</button>
                      </div>
                      <div class="mx-auto">
                        <p class="text-center fs-14 fw-400 text-secondary-one">
                          Want to create an account?
                          <router-link :to="{ name:'register' }" class="text-primary-four">Sign up</router-link>
                        </p>
                      </div>
                    </div>

<!--                    &lt;!&ndash; Email button... &ndash;&gt;-->
<!--                    <div class="_button cursor-pointer flex gap-5 items-center">-->
<!--                        <span class="icon">-->
<!--                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <circle cx="20" cy="20" r="20" fill="#EDEDED"/>-->
<!--                                <path d="M11 13.25H29V26C29 26.1989 28.921 26.3897 28.7803 26.5303C28.6397 26.671 28.4489 26.75 28.25 26.75H11.75C11.5511 26.75 11.3603 26.671 11.2197 26.5303C11.079 26.3897 11 26.1989 11 26V13.25Z" stroke="#34373F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                <path d="M29 13.25L20 21.5L11 13.25" stroke="#34373F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                            </svg>-->
<!--                        </span>-->
<!--                        <div>-->
<!--                            <p class="fs-14 fw-600 text-primary-one">Email</p>-->
<!--                            <p class="fs-16 fw-400 text-secondary-two"><span>*****</span>er@gmail.com</p>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    &lt;!&ndash; Mobile button... &ndash;&gt;-->
<!--                    <div class="_button active cursor-pointer flex gap-5 items-center mt-10">-->
<!--                        <span class="icon">-->
<!--                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <circle cx="20" cy="20" r="20" fill="#EDEDED"/>-->
<!--                                <path d="M26 28.25V11.75C26 10.9216 25.3284 10.25 24.5 10.25H15.5C14.6716 10.25 14 10.9216 14 11.75L14 28.25C14 29.0784 14.6716 29.75 15.5 29.75H24.5C25.3284 29.75 26 29.0784 26 28.25Z" stroke="#34373F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                            </svg>-->
<!--                        </span>-->
<!--                        <div>-->
<!--                            <p class="fs-14 fw-600 text-primary-one">Mobile</p>-->
<!--                            <p class="fs-16 fw-400 text-secondary-two"><span>*****</span>894</p>-->
<!--                        </div>-->
<!--                    </div>-->

                </form>
            </div>

        </div>
    </div>
</template>




<script>
    export default {
        created(){
            document.title = 'Reset Password Link'
        },

        data() {
            return {
                cred: {},
                backgroundImage: `url(${require('@/assets/images/loginPage.webp')})`
            }
        },
        methods: {
          generateResetLink(e) {
                e.preventDefault();
                if(this.cred.email) {
                    this.$Progress.start();
                    this.$store.dispatch("generatePasswordLink", this.cred).then(response => {
                      this.$Progress.finish();
                      this.cred = {};
                    });
                } else {
                    alert('Please fill the email field');
                }
            }
        }
    }
</script>


<style>
  .i-window {
    width: 100%;
    height: 100vh;
  }
</style>

<style scoped>
    a[href="/"]{
      user-select: all;
      pointer-events: auto;
      opacity: 1;
      cursor: pointer;
    }
    .auth_wrapper{
      height: 100vh;
      width: 100vw;
      overflow-x: hidden;
      background-color: var(--color-primary-three);
    }
    .auth_wrapper .left{
      width: 55%;
      background-image: linear-gradient(rgb(65 161 212 / 95%), rgb(43 128 173 / 89%)), var(--bg);
      background-repeat: no-repeat;
      background-position: center;
      padding-left: 5%;
      padding-right: 5%;
      display: grid;
      place-content: center;
    }
    .phone_number{
      display: flex;
    }
    .auth_wrapper .right{
      width: 45%;
      padding-left: 11%;
      padding-right: 11%;
      padding-top: 220px
    }

    .form_group input{
      width: 100%;
      height: 46px;
      background: transparent;
      border: 1px solid #C9C9C9;
      border-radius: 4px;
      line-height: 46px;
      padding: 12px 16px 12px 16px;
      margin-bottom: 32px;
    }

    .form_group svg{
      position: absolute;
      right: 10px;
      top: 32%;
      transform: translateY(-50%)
    }

    ._button{
        height: 76px;
        background: #FFFFFF;
        border: 1px solid #E2E2E2;
        border-radius: 4px;
        padding: 18px 24px;
    }
    ._button:is(:hover, .active){
        border-color: #2B80AD;
        box-shadow: 0px 6px 15px rgba(35, 39, 47, 0.13);
    }

    @media all and (max-width: 1440px){
      .auth_wrapper .left{
        width: 50%;
      }
      .auth_wrapper .left .title{
        font-size: 42px;
        line-height: 64px;
      }
      .auth_wrapper .description{
        font-size: 28px;
        line-height: 47px;
      }
      .auth_wrapper .right{
        width: 50%;
        padding-left: 8%;
        padding-right: 8%;
        padding-top: 220px;
      }
    }

    @media all and (max-width: 1100px){
    .auth_wrapper .left .title{
        font-size: 30px;
        line-height: 64px;
      }
      .auth_wrapper .description{
        font-size: 20px;
        line-height: 35px;
      }
    }

    @media all and (max-width: 1025px) and (min-width: 1000px){
        .auth_wrapper .right{
            padding-top: 160px;
        }
    }
    @media all and (max-width: 1000px){
        .auth_wrapper .right{
            max-width: 600px;
            width: 100%;
            padding: 0 10%;
            margin: auto;
            padding-bottom: 50px;
        }
        .auth_wrapper{
            height: 100vh;
        }
        .auth_wrapper .left{
            display: none;
        }
        .formTopText{
            text-align: center;
        }
        .logo{
            width: 190px;
            position: static;
            margin: auto;
            margin-top: 110px;
            margin-bottom: 32px;
        }
        .logo>svg{
            width: 100%;
        }

        .welcome-text{
            font-size: 20px;
            text-align: center;
        }
        .subtitle{
            font-size: 14px;
            text-align: center;
        }
    }
</style>
